import {
  WidgetContainer,
  Grid,
  Typography,
  Divider,
  S6,
  useTranslations,
  Button,
  INumericTextFieldProps,
  ReactHookFormAutocomplete,
  NumericTextField,
  TFunction,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { useCallback, memo, SyntheticEvent } from 'react';
import {
  RestoreDataRequest,
  RestoreDataType,
  GetRetentionPeriodResponse,
} from '@uniqkey-backend-organization-web/api-client';

export interface IRestoreDataSubmitResult extends RestoreDataRequest {}

interface IRestoreDataFormValue {
  daysToRestore: string;
  restoreDataTypes: IRestoreDataTypesOption[];
}

interface IRestoreDataTypesOption {
  label: string;
  value: RestoreDataType;
}

interface IRestoreDataWidgetProps {
  retentionPeriodInDays: GetRetentionPeriodResponse['retentionPeriodInDays'];
  isRetentionPeriodDataLoading: boolean;
  onSubmit: (value: IRestoreDataSubmitResult) => Promise<void>;
  isLoading: boolean;
}

const getRestoreDataTypesOptions = (t: TFunction) => [
  { label: t('restoreDataTypes.allData'), value: RestoreDataType.AllData },
  { label: t('restoreDataTypes.Employee'), value: RestoreDataType.Employee },
  { label: t('restoreDataTypes.PaymentCards'), value: RestoreDataType.PaymentCards },
  { label: t('restoreDataTypes.SecureNotes'), value: RestoreDataType.SecureNotes },
  { label: t('restoreDataTypes.Logins'), value: RestoreDataType.Logins },
  { label: t('restoreDataTypes.AuditLogs'), value: RestoreDataType.AuditLogs },
];

const RestoreDataWidget = (props: IRestoreDataWidgetProps) => {
  const {
    retentionPeriodInDays,
    isRetentionPeriodDataLoading,
    onSubmit,
    isLoading,
  } = props;
  const { t } = useTranslations();

  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IRestoreDataFormValue>({
    mode: 'all',
    defaultValues: {
      daysToRestore: '',
      restoreDataTypes: [],
    },
  });

  const [
    watchDaysToRestore,
    watchRestoreDataTypes,
  ] = watch([
    'daysToRestore',
    'restoreDataTypes',
  ]);

  const handleDaysToRestoreChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue('daysToRestore', event.target.value);
  }, [setValue]);

  const handleRestoreDataTypesChange = useCallback((
    event: SyntheticEvent,
    value: IRestoreDataTypesOption[],
  ) => {
    if (!value.length) return;
    const lastAddedRestoreDataType = value[value.length - 1];
    const actualRestoreDataTypes = value.filter((
      restoreDataType,
    ) => restoreDataType.value !== RestoreDataType.AllData);
    if (lastAddedRestoreDataType.value === RestoreDataType.AllData) {
      setValue('restoreDataTypes', [lastAddedRestoreDataType]);
      return;
    }
    setValue('restoreDataTypes', actualRestoreDataTypes);
  }, [setValue]);

  const handleFormSubmit = useCallback(async (
    formValue: IRestoreDataFormValue,
  ): Promise<void> => {
    try {
      const {
        daysToRestore,
        restoreDataTypes,
      } = formValue;

      const parsedDaysToRestore = parseInt(daysToRestore, 10);
      const parsedRestoreDataTypes = restoreDataTypes.map(
        (restoreDataType) => restoreDataType.value,
      );

      await onSubmit({
        daysToRestore: parsedDaysToRestore,
        restoreDataTypes: parsedRestoreDataTypes,
      });
      reset();
    } catch (e) {
      throw new Error();
    }
  }, [onSubmit, reset]);

  const disabled = !!errors.daysToRestore || !watchDaysToRestore || !watchRestoreDataTypes.length;

  if (isRetentionPeriodDataLoading) {
    return (
      <WidgetContainer
        container
        withShadow
        minHeight={280}
        p={3}
        isLoading={isRetentionPeriodDataLoading}
      />
    );
  }

  return (
    <WidgetContainer container withShadow p={3}>
      <Grid container flexDirection="column">
        <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
          <Grid item mb={0.5}>
            <Typography variant="body3" color={S6}>
              {t('settingsPage.retentionPeriodTab.restoreDataWidget.title')}
            </Typography>
          </Grid>
          <Divider />
          <Grid item container flexDirection="column" mt={2.5} rowGap={2}>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt="12px" width={135}>
                <Typography>
                  {t('settingsPage.retentionPeriodTab.restoreDataWidget.restoreForTime.title')}
                </Typography>
              </Grid>
              <Grid item xs>
                <NumericTextField
                  name="daysToRestore"
                  decimalSeparator=""
                  allowNegative={false}
                  fullWidth
                  autoComplete="off"
                  error={!!errors.daysToRestore}
                  helperText={errors.daysToRestore?.message}
                  placeholder={t('settingsPage.retentionPeriodTab.textField.placeholder')}
                  value={watchDaysToRestore}
                  onChange={handleDaysToRestoreChange}
                  inputProps={{
                    ...register('daysToRestore', {
                      validate: (v) => (
                        parseInt(v as string, 10) > retentionPeriodInDays
                          ? t('validation.restoreDataShouldNotExceedRetentionPeriod')
                          : true
                      ),
                    }),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt={1.5} width={135}>
                <Typography>
                  {t('settingsPage.retentionPeriodTab.restoreDataWidget.dataForRestore.title')}
                </Typography>
              </Grid>
              <Grid item xs>
                <ReactHookFormAutocomplete
                  name="restoreDataTypes"
                  placeholder={t(
                    'settingsPage.retentionPeriodTab.restoreDataWidget.dataForRestore.placeholder',
                  )}
                  options={getRestoreDataTypesOptions(t)}
                  control={control}
                  multiple
                  fullWidth
                  onChange={handleRestoreDataTypesChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item mt={3} justifyContent="flex-end">
            <Button variant="outlined" type="submit" isLoading={isLoading} disabled={disabled}>
              {t('common.apply')}
            </Button>
          </Grid>
        </form>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(RestoreDataWidget);
