import {
  memo, useCallback, useMemo, useState,
} from 'react';
import {
  Box,
  EditableContainer,
  EntityDetailsContainer,
  Grid,
  Label,
  Typography,
  TypographyWithTooltip,
  useSnackbar,
  useTranslations,
  formatDate,
} from '@uniqkey-frontend/shared-app';
import { GetGroupByIdV2Response } from '@uniqkey-backend-organization-web/api-client';
import {
  useGetGroupById,
  useGetOrganizationScimSettings,
  usePatchGroupById,
} from '../../../../hooks/reactQuery';
import EditGroupModal, { IEditGroupModalSubmitResult } from '../EditGroupModal';
import { createReplacePatchOperation } from '../../../../helpers/apiClients';

interface IGroupDetailsWidgetProps {
  groupId: string;
}

const GroupDetailsWidget = (props: IGroupDetailsWidgetProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);
  const [isEditGroupLoading, setIsEditGroupLoading] = useState(false);
  const handleEditGroupModalClose = useCallback(() => setIsEditGroupModalOpen(false), []);
  const handleEditGroupModalOpen = useCallback(() => setIsEditGroupModalOpen(true), []);
  const {
    data: group,
    isLoading: isGetGroupByIdLoading,
    isError: isGetGroupByIdError,
  } = useGetGroupById(
    { groupId },
  );

  const {
    data: scim,
    isLoading: isGetOrganizationScimSettingsLoading,
    isError: isGetOrganizationScimSettingsError,
  } = useGetOrganizationScimSettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const {
    name,
    description,
    createdAt,
    externalCreationId,
    canManageVaults,
    createdByEmployeeAccountName,
    createdByEmployeeAccountEmail,
    allowCustomName,
    initialName,
  } = group ?? {} as GetGroupByIdV2Response;
  const creationDate = useMemo(() => formatDate({ date: createdAt }), [createdAt]);

  const { showError, showSuccess } = useSnackbar();
  const { secretToken } = scim ?? {};
  const isScim = !!externalCreationId && !!secretToken;
  const { mutate } = usePatchGroupById({ groupId, useOptimisticUpdates: true });
  const handleGroupEdit = useCallback((value: IEditGroupModalSubmitResult) => {
    setIsEditGroupLoading(true);
    const operations = [
      createReplacePatchOperation('/name', value.name),
    ];
    if (!isScim) {
      operations.push(createReplacePatchOperation('/description', value.description!));
    }
    mutate(operations, {
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSuccess: () => {
        showSuccess({
          text: t('groupPage.settingsTab.settings.allowCustomName.groupDetailsUpdated'),
        });
        handleEditGroupModalClose();
      },
      onSettled: () => setIsEditGroupLoading(false),
    });
  }, [isScim, mutate, showError, t, showSuccess, handleEditGroupModalClose]);

  const isLoading = isGetGroupByIdLoading || isGetOrganizationScimSettingsLoading;
  const isError = isGetGroupByIdError || isGetOrganizationScimSettingsError;

  if (isError) {
    return null;
  }

  return (
    <EntityDetailsContainer container justifyContent="space-between" isLoading={isLoading}>
      <Grid item xs={8} container flexDirection="column">
        <Box mb={3} mr={1} width="100%">
          <EditableContainer
            item
            container
            flexDirection="column"
            p={1}
            disabled={isScim && !allowCustomName}
            onClick={handleEditGroupModalOpen}
          >
            <Typography variant="h6" noWrap width="100%">
              {name}
            </Typography>
            {!!description && !isScim && (
              <>
                <Box mt={1} />
                <Typography lineClamp={3}>
                  {description}
                </Typography>
              </>
            )}
          </EditableContainer>
          {!!description && isScim && (
            <TypographyWithTooltip lineClamp={3} ml={1}>
              {description}
            </TypographyWithTooltip>
          )}
        </Box>
        <Grid item container flexDirection="column">
          <Grid item container>
            <Typography variant="subtitle1">
              {t('groupDetailsWidget.created')}
              :
            </Typography>
            &nbsp;
            <Typography>{creationDate}</Typography>
          </Grid>
          {!externalCreationId && (
            <Grid item container>
              <Typography variant="subtitle1">
                {t('groupDetailsWidget.createdBy')}
                :
              </Typography>
              &nbsp;
              <Typography noWrap>
                {createdByEmployeeAccountName ?? createdByEmployeeAccountEmail}
              </Typography>
            </Grid>
          )}
          {isScim && allowCustomName && (
            <Grid item container>
              <Typography variant="subtitle1">
                {t('groupDetailsWidget.initialName')}
                :
              </Typography>
              &nbsp;
              <Typography noWrap>
                {initialName}
              </Typography>
            </Grid>
          )}
          {!!externalCreationId && !secretToken && (
            <Grid item container>
              <Typography variant="subtitle1">
                {t('groupDetailsWidget.createdBy')}
                :
              </Typography>
              &nbsp;
              <Typography noWrap>
                {t('scim.scim')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4} container flexDirection="column" alignItems="end">
        {canManageVaults && (
          <Box mb={1}><Label text={t('groupDetailsWidget.canManageLogins')} /></Box>
        )}
        {isScim && <Label text={t('groupDetailsWidget.scim')} />}
      </Grid>
      {isEditGroupModalOpen && (
        <EditGroupModal
          isOpen={isEditGroupModalOpen}
          onClose={handleEditGroupModalClose}
          onSubmit={handleGroupEdit}
          name={name}
          description={description}
          isLoading={isEditGroupLoading}
          initialName={initialName}
          isScim={isScim}
        />
      )}
    </EntityDetailsContainer>
  );
};

export default memo(GroupDetailsWidget);
