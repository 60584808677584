/* eslint-disable max-len */
const TOO_MANY_ITEMS_MOVE_TO_PRIVATE_KEYCHAIN_ERROR = 'UniqKey_OrganizationApi_Web_Commands_VaultCommands_MoveToPrivateKeychainBulk_MoveToPrivateKeychainBulkCommand_Too_many_vaults';
const TOO_MANY_ITEMS_MOVE_TO_EMPLOYEE_ERROR = 'UniqKey_OrganizationApi_Web_Commands_VaultCommands_MoveToEmployeeBulk_MoveToEmployeeBulkCommand_Too_many_vaults';
const TOO_MANY_ITEMS_MOVE_TO_GROUP_ERROR = 'UniqKey_OrganizationApi_Web_Commands_VaultCommands_MoveToGroupBulk_MoveToGroupBulkCommand_Too_many_vaults';
const TOO_MANY_ITEMS_DETACH_ERROR = 'UniqKey_OrganizationApi_Web_Commands_VaultCommands_DetachBulk_DetachBulkVaultCommand_Too_many_vaults';
const TOO_MANY_ITEMS_DELETE_ERROR = 'UniqKey_OrganizationApi_Web_Commands_VaultCommands_DeleteBulk_DeleteBulkVaultCommand_Too_many_vaults';
const TOO_MANY_ITEMS_DELETE_VAULT_TO_EMPLOYEE_ERROR = 'UniqKey_OrganizationApi_Web_Commands_VaultToEmployeeAccountCommands_DeleteBulk_DeleteBulkVaultToEmployeeAccountCommand_Too_many_vaults';
const TOO_MANY_ITEMS_DELETE_VAULT_TO_GROUP_ERROR = 'UniqKey_OrganizationApi_Web_Commands_VaultToGroupCommands_DeleteBulk_DeleteBulkVaultToGroupCommand_Too_many_vaults';
const TOO_MANY_ITEMS_SHARE_VAULT_TO_GROUP_ERROR = 'UniqKey_OrganizationApi_Web_Commands_VaultToGroupCommands_Add_AddVaultToGroupCommand_Too_many_vaults';
const TOO_MANY_ITEMS_INVITE_EMPLOYEES_ERROR = 'UniqKey_OrganizationApi_Web_Commands_EmployeeAccountCommands_InviteBulk_InviteBulkEmployeeCommand_Too_many_invites';
const TOO_MANY_ITEMS_DELETE_EMPLOYEES_ERROR = 'UniqKey_OrganizationApi_Web_Commands_EmployeeAccountCommands_DeleteBulk_DeleteBulkEmployeeAccountCommand_Too_many_employees_to_delete';
const TOO_MANY_ITEMS_ARCHIVE_EMPLOYEES_ERROR = 'UniqKey_OrganizationApi_Web_Commands_EmployeeAccountCommands_ArchiveBulk_ArchiveBulkEmployeeAccountCommand_Too_many_employees_to_archive';
const TOO_MANY_ITEMS_RESTORE_EMPLOYEES_ERROR = 'UniqKey_OrganizationApi_Web_Commands_EmployeeAccountCommands_RestoreBulk_RestoreBulkEmployeeAccountCommand_Too_many_employees_to_restore';
const TOO_MANY_ITEMS_REMOVE_EMPLOYEES_FROM_GROUP_ERROR = 'UniqKey_OrganizationApi_Web_Commands_EmployeeAccountsToGroupsCommands_DetachBulk_DetachBulkEmployeeAccountToGroupCommand_Too_many_employees_to_remove_from_group';
const TOO_MANY_ITEMS_ADD_EMPLOYEES_TO_GROUP_ERROR = 'UniqKey_OrganizationApi_Web_Commands_EmployeeAccountsToGroupsCommands_Attach_AttachEmployeeAccountCommand_Too_many_employees_to_add_to_group';
const TOO_MANY_ITEMS_ADD_DELETE_GROUPS_ERROR = 'UniqKey_OrganizationApi_Web_Commands_GroupCommands_DeleteBulk_DeleteBulkGroupCommand_Too_many_groups_to_remove';
/* eslint-enable max-len */

const ERROR_MESSAGE_TO_ERROR_TRANSLATION_KEY_MAP: { [key: string]: string } = {
  [TOO_MANY_ITEMS_MOVE_TO_PRIVATE_KEYCHAIN_ERROR]: 'toasts.tooManyItems.moveToPrivateKeychain',
  [TOO_MANY_ITEMS_MOVE_TO_EMPLOYEE_ERROR]: 'toasts.tooManyItems.moveToEmployee',
  [TOO_MANY_ITEMS_MOVE_TO_GROUP_ERROR]: 'toasts.tooManyItems.moveToGroup',
  [TOO_MANY_ITEMS_DETACH_ERROR]: 'toasts.tooManyItems.detach',
  [TOO_MANY_ITEMS_DELETE_ERROR]: 'toasts.tooManyItems.delete',
  [TOO_MANY_ITEMS_DELETE_VAULT_TO_EMPLOYEE_ERROR]: 'toasts.tooManyItems.deleteVaultToEmployee',
  [TOO_MANY_ITEMS_DELETE_VAULT_TO_GROUP_ERROR]: 'toasts.tooManyItems.deleteVaultToGroup',
  [TOO_MANY_ITEMS_SHARE_VAULT_TO_GROUP_ERROR]: 'toasts.tooManyItems.shareVaultToGroup',
  [TOO_MANY_ITEMS_INVITE_EMPLOYEES_ERROR]: 'toasts.tooManyItems.inviteEmployees',
  [TOO_MANY_ITEMS_DELETE_EMPLOYEES_ERROR]: 'toasts.tooManyItems.deleteEmployees',
  [TOO_MANY_ITEMS_ARCHIVE_EMPLOYEES_ERROR]: 'toasts.tooManyItems.archiveEmployee',
  [TOO_MANY_ITEMS_RESTORE_EMPLOYEES_ERROR]: 'toasts.tooManyItems.restoreEmployee',
  [TOO_MANY_ITEMS_REMOVE_EMPLOYEES_FROM_GROUP_ERROR]:
    'toasts.tooManyItems.removeEmployeesFromGroup',
  [TOO_MANY_ITEMS_ADD_EMPLOYEES_TO_GROUP_ERROR]: 'toasts.tooManyItems.addEmployeesToGroup',
  [TOO_MANY_ITEMS_ADD_DELETE_GROUPS_ERROR]: 'toasts.tooManyItems.deleteGroups',
};

// TODO: Remove when more exports will be added
// eslint-disable-next-line import/prefer-default-export
export const getTranslationKeyByError = (error: any) => {
  const errorMessageTranslationKey = ERROR_MESSAGE_TO_ERROR_TRANSLATION_KEY_MAP[
    error.response?.data.trim()
  ];
  return errorMessageTranslationKey ?? 'common.somethingWentWrong';
};
