import {
  WidgetContainer,
  Grid,
  Typography,
  Divider,
  S6,
  S5,
  useTranslations,
  Button,
  INumericTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  UpdateRetentionPeriodRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { useCallback, memo } from 'react';
import NumericTextFieldWithDefaultHelperText from '../NumericTextFieldWithDefaultHelperText';
import { IGeneralRetentionPeriodWidgetProps } from '../GeneralRetentionPeriodWidget';

export interface IUpdateGeneralRetentionPeriodSubmitResult extends UpdateRetentionPeriodRequest {}

interface IUpdateGeneralRetentionPeriodFormValue {
  retentionPeriodInDays: string;
}

interface IGeneralRetentionPeriodWidgetContainerProps extends Pick<
  IGeneralRetentionPeriodWidgetProps, 'retentionPeriodInDays' | 'onSubmit' | 'isUpdating'
> {}

const GeneralRetentionPeriodWidgetContainer = (
  props: IGeneralRetentionPeriodWidgetContainerProps,
) => {
  const { retentionPeriodInDays, onSubmit, isUpdating } = props;
  const { t } = useTranslations();

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IUpdateGeneralRetentionPeriodFormValue>({
    mode: 'all',
    defaultValues: {
      retentionPeriodInDays: retentionPeriodInDays?.toString() ?? '',
    },
  });

  const [watchRetentionPeriodInDays] = watch(['retentionPeriodInDays']);

  const handleGeneralRetentionPeriodInDaysChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue('retentionPeriodInDays', event.target.value);
  }, [setValue]);

  const handleFormSubmit = useCallback((
    formValue: IUpdateGeneralRetentionPeriodFormValue,
  ): void => {
    const {
      retentionPeriodInDays: preRetentionPeriodInDays,
    } = formValue;

    const parsedRetentionPeriodInDays = parseInt(preRetentionPeriodInDays, 10);
    onSubmit({
      retentionPeriodInDays: parsedRetentionPeriodInDays,
    });
    reset({ retentionPeriodInDays: preRetentionPeriodInDays });
  }, [onSubmit, reset]);

  const disabled = !isDirty || !!errors.retentionPeriodInDays;

  return (
    <WidgetContainer container withShadow p={3}>
      <Grid container flexDirection="column">
        <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
          <Grid item mb={0.5}>
            <Typography variant="body3" color={S6}>
              {t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.title')}
            </Typography>
          </Grid>
          <Divider />
          <Grid item mt={0.5}>
            <Typography variant="body2" color={S5}>
              {t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.description')}
            </Typography>
          </Grid>
          <Grid item container flexDirection="column" mt={0.5} rowGap={2}>
            <Grid container gap={1}>
              {/* Same padding as on TextField to center text */}
              <Grid item pt="12px" width={140}>
                <Typography>
                  {/* eslint-disable-next-line max-len */}
                  {t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.retentionPeriod.title')}
                </Typography>
              </Grid>
              <NumericTextFieldWithDefaultHelperText
                name="retentionPeriodInDays"
                error={!!errors.retentionPeriodInDays}
                helperText={errors.retentionPeriodInDays?.message}
                // eslint-disable-next-line max-len
                placeholder={t('settingsPage.retentionPeriodTab.textField.placeholder')}
                value={watchRetentionPeriodInDays}
                onChange={handleGeneralRetentionPeriodInDaysChange}
                inputProps={{
                  ...register('retentionPeriodInDays', {
                    validate: (v) => {
                      const parsedValue = parseInt(v as string, 10);
                      return !parsedValue || parsedValue < 30
                        ? t('validation.atLeast30Days')
                        : true;
                    },
                  }),
                }}
                showDefaultHelperText={!errors.retentionPeriodInDays}
                defaultHelperText={t(
                  'settingsPage.retentionPeriodTab.textField.info',
                )}
              />
            </Grid>
          </Grid>
          <Grid container item mt={3} justifyContent="flex-end">
            <Button variant="outlined" type="submit" isLoading={isUpdating} disabled={disabled}>
              {isDirty ? t('common.apply') : t('common.change')}
            </Button>
          </Grid>
        </form>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(GeneralRetentionPeriodWidgetContainer);
