import { Grid } from '@uniqkey-frontend/shared-app';
import DashboardGroupSecurityScoreWidget from './components/DashboardGroupSecurityScoreWidget';

const DashboardGroupsWidgets = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} lg={6}>
      <DashboardGroupSecurityScoreWidget />
    </Grid>
  </Grid>
);

export default DashboardGroupsWidgets;
