import {
  ScrollablePanelContent,
  Grid,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { useCallback, useState } from 'react';
import { GetRetentionPeriodResponse } from '@uniqkey-backend-organization-web/api-client';
import RetentionPeriodWidget from './components/RetentionPeriodWidget';
import {
  useGetOrganizationRetentionPeriod,
  useUpdateSoftDeletionRetentionPeriod,
  useUpdateGeneralRetentionPeriod,
} from '../../../../hooks/reactQuery';
import SoftDeletionPeriodWidget from './components/SoftDeletionPeriodWidget';
import {
  IUpdateSoftDeletionRetentionPeriodSubmitResult,
} from './components/SoftDeletionPeriodWidgetContainer';
import GeneralRetentionPeriodWidget from './components/GeneralRetentionPeriodWidget';
import {
  IUpdateGeneralRetentionPeriodSubmitResult,
} from './components/GeneralRetentionPeriodWidgetContainer';
import RestoreDataWidget, { IRestoreDataSubmitResult } from './components/RestoreDataWidget';
import useOrganizationSecuritySettingsAPI
  from '../../../../hooks/useOrganizationSecuritySettingsAPI';
import { logException } from '../../../../services/sentryService';

const RetentionPeriodTab = () => {
  const { showError, showSuccess } = useSnackbar();
  const { t } = useTranslations();

  const {
    data: retentionPeriodData,
    isLoading: isRetentionPeriodDataLoading,
    isError: isRetentionPeriodDataError,
  } = useGetOrganizationRetentionPeriod();

  const {
    softDeletionPeriodInDaysForArchivedEmployees,
    softDeletionPeriodInDaysForUnmanagedVaults,
    softDeletionPeriodInDaysForAuditLogs,
    retentionPeriodInDays,
  } = retentionPeriodData ?? {} as GetRetentionPeriodResponse;

  const {
    mutate: updateSoftDeletionRetentionPeriod,
    isLoading: isSoftDeletionRetentionPeriodUpdating,
  } = useUpdateSoftDeletionRetentionPeriod({ useOptimisticUpdates: true });

  const {
    mutate: updateGeneralRetentionPeriod,
    isLoading: isGeneralRetentionPeriodUpdating,
  } = useUpdateGeneralRetentionPeriod({ useOptimisticUpdates: true });

  const { restoreData } = useOrganizationSecuritySettingsAPI();

  const [isRestoreDataLoading, setIsRestoreDataLoading] = useState(false);

  const handleUpdateSoftDeletionRetentionPeriod = useCallback((
    value: IUpdateSoftDeletionRetentionPeriodSubmitResult,
  ) => {
    updateSoftDeletionRetentionPeriod(
      value,
      {
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message: 'RetentionPeriodTab/handleUpdateSoftDeletionRetentionPeriod exception',
          });
        },
        onSuccess: () => {
          showSuccess({
            text: t('settingsPage.retentionPeriodTab.softDeletionPeriodWidget.success.message'),
          });
        },
      },
    );
  }, [showError, showSuccess, t, updateSoftDeletionRetentionPeriod]);

  const handleUpdateGeneralRetentionPeriod = useCallback((
    value: IUpdateGeneralRetentionPeriodSubmitResult,
  ) => {
    updateGeneralRetentionPeriod(
      value,
      {
        onError: (e) => {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message: 'RetentionPeriodTab/handleUpdateGeneralRetentionPeriod exception',
          });
        },
        onSuccess: () => {
          showSuccess({
            text: t('settingsPage.retentionPeriodTab.generalRetentionPeriodWidget.success.message'),
          });
        },
      },
    );
  }, [showError, showSuccess, t, updateGeneralRetentionPeriod]);

  const handleRestoreData = useCallback(async (value: IRestoreDataSubmitResult) => {
    try {
      setIsRestoreDataLoading(true);
      await restoreData(value);
      showSuccess({
        text: t('settingsPage.retentionPeriodTab.restoreDataWidget.success.message'),
      });
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, { message: 'RetentionPeriodTab/handleRestoreData exception' });
    } finally {
      setIsRestoreDataLoading(false);
    }
  }, [restoreData, showError, showSuccess, t]);

  return (
    <ScrollablePanelContent p={3}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid container item xs={6} rowGap={3}>
          <RetentionPeriodWidget />
          <SoftDeletionPeriodWidget
            data={{
              softDeletionPeriodInDaysForArchivedEmployees,
              softDeletionPeriodInDaysForUnmanagedVaults,
              softDeletionPeriodInDaysForAuditLogs,
            }}
            isLoading={isRetentionPeriodDataLoading}
            isError={isRetentionPeriodDataError}
            onSubmit={handleUpdateSoftDeletionRetentionPeriod}
            isUpdating={isSoftDeletionRetentionPeriodUpdating}
          />
        </Grid>
        <Grid container item xs={6} rowGap={3}>
          <GeneralRetentionPeriodWidget
            retentionPeriodInDays={retentionPeriodInDays}
            isLoading={isRetentionPeriodDataLoading}
            isError={isRetentionPeriodDataError}
            onSubmit={handleUpdateGeneralRetentionPeriod}
            isUpdating={isGeneralRetentionPeriodUpdating}
          />
          <RestoreDataWidget
            retentionPeriodInDays={retentionPeriodInDays}
            isRetentionPeriodDataLoading={isRetentionPeriodDataLoading}
            isLoading={isRestoreDataLoading}
            onSubmit={handleRestoreData}
          />
        </Grid>
      </Grid>
    </ScrollablePanelContent>
  );
};

export default RetentionPeriodTab;
