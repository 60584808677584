import {
  useCallback, memo, useState, useMemo,
} from 'react';
import {
  useSnackbar, useTranslations, IAutocompleteDefaultOption,
} from '@uniqkey-frontend/shared-app';
import {
  GetEmployeeAccountByIdResponse,
  GetVaultCreditCardsResponseModel,
  GetVaultPasswordsResponseModel,
  GetVaultSecureNotesResponseModel,
  GetEmployeeAccountsV2ResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import EntitySelectorModal, {
  TAutocompleteValue, TEntitySelectorModalOnSubmit,
} from '../EntitySelectorModal';
import useEmployeeAccountsAPI from '../../hooks/useEmployeeAccountsAPI';
import { logException } from '../../services/sentryService';
import { getActiveOrganizationId } from '../../services/organizationService';
import { useTrustedPortalStore } from '../../modules/TrustedPortalModule/store';
import VaultTypeNameEnum from '../../enums/VaultTypeNameEnum';
import VaultTypeEnum from '../../enums/VaultTypeEnum';
import useVaultsAPI from '../../hooks/useVaultsAPI';
import { getTranslationKeyByError } from '../../helpers/errorService';

interface IMoveVaultsToEmployeeModalProps {
  isOpen: boolean;
  onClose: () => void;
  vaults: GetVaultPasswordsResponseModel[] | GetVaultSecureNotesResponseModel[]
    | GetVaultCreditCardsResponseModel[];
  onSubmit: () => void;
  type: VaultTypeEnum;
}

interface IParsedEntity {
  id: GetEmployeeAccountByIdResponse['employeeAccountId'];
  label: GetEmployeeAccountByIdResponse['email'];
}

const MoveVaultsToEmployeeModal = (props: IMoveVaultsToEmployeeModalProps) => {
  const {
    isOpen, onClose, vaults, onSubmit, type,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [loadedEmployeeAccountsCount, setLoadedEmployeeAccountsCount] = useState(0);
  const [totalLoadedEmployeeAccountsCount, setTotalLoadedEmployeeAccountsCount] = useState(0);
  const [response, setResponse] = useState<GetEmployeeAccountsV2ResponseModel[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<TAutocompleteValue>(null);
  const { t } = useTranslations();
  const { showWarning, showError, showSuccess } = useSnackbar();
  const { getEmployeeAccounts } = useEmployeeAccountsAPI();
  const { moveVaultsToEmployee } = useVaultsAPI();

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleChangeAutocompleteValue = useCallback(
    (value: TAutocompleteValue) => setAutocompleteValue(value),
    [],
  );

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data, total } = await getEmployeeAccounts({
        page: 1,
        pageLength: 200,
        searchQuery,
        showProcessingUsers: false,
      });
      setLoadedEmployeeAccountsCount(data.length);
      setTotalLoadedEmployeeAccountsCount(total);
      setResponse(data);
      return data;
    } catch (e) {
      setLoadedEmployeeAccountsCount(0);
      setTotalLoadedEmployeeAccountsCount(0);
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'MoveVaultsToEmployeeModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getEmployeeAccounts, showError, t]);

  const vaultType = useMemo(
    () => VaultTypeNameEnum[VaultTypeEnum[type] as keyof typeof VaultTypeEnum],
    [type],
  );

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity>
  >(async (value) => {
    try {
      setIsLoading(true);
      const vaultIds = vaults.map((vault) => vault.vaultId);
      await moveVaultsToEmployee({ vaultIds, employeeAccountId: value.id });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t(
            `trustedPortalSuccessNotifications.${vaultType}.movedToEmployee`,
            { count: vaults.length, name: value.label },
          ),
        });
      } else {
        showWarning({ text: t('moveEntityToEmployeeModal.approvalOnMobileToast') });
      }
      onSubmit();
      setIsLoading(false);
      onClose();
    } catch (e) {
      showError({ text: t(getTranslationKeyByError(e)) });
      logException(e, {
        message: 'MoveVaultsToEmployeeModal/handleSubmitEntitySelectorModal exception',
      });
      setIsLoading(false);
    }
  }, [
    vaults,
    isTrustedPortalEnabled,
    onSubmit,
    onClose,
    showSuccess,
    t,
    vaultType,
    showWarning,
    showError,
    moveVaultsToEmployee,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (employeeAccountsToParse: GetEmployeeAccountByIdResponse[]) => employeeAccountsToParse.map(
      (employeeAccount) => {
        const processedLabel = employeeAccount.name
          ? `${employeeAccount.email} (${employeeAccount.name})`
          : employeeAccount.email;
        return {
          id: employeeAccount.employeeAccountId,
          label: processedLabel,
        };
      },
    ),
    [],
  );

  return (
    <EntitySelectorModal
      data={response}
      dataKey="employeeAccountId"
      isOpen={isOpen}
      title={t(`moveEntityToEmployeeModal.${vaultType}.title`, { count: vaults.length })}
      dialogContentText={autocompleteValue
        ? t('moveEntityToEmployeeModal.descriptionBy', {
          userName: (autocompleteValue as unknown as IAutocompleteDefaultOption).label,
        })
        : t('moveEntityToEmployeeModal.description')}
      placeholder={t('moveEntityToEmployeeModal.placeholder')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isLoading}
      multiple={false}
      submitButtonText="common.move"
      loadedOptionsCount={loadedEmployeeAccountsCount}
      totalLoadedOptionsCount={totalLoadedEmployeeAccountsCount}
      onAutocompleteValueChange={handleChangeAutocompleteValue}
    />
  );
};

export default memo(MoveVaultsToEmployeeModal);
