import { useCallback, memo } from 'react';
import {
  ListItem,
  S2,
  S1,
  Grid,
  SecurityScoreIcon,
  AB1,
  Tooltip,
  useTranslations,
  Typography,
  S6,
} from '@uniqkey-frontend/shared-app';
import {
  ApplicationOrderPropertyName, GetLowSecurityGroupsResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { lowerFirst } from 'lodash';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import { GroupModuleTabEnum } from '../../../../../GroupPage/components/GroupModule';

interface IDashboardGroupSecurityScoreWidgetListItemProps {
  group: GetLowSecurityGroupsResponseModel;
  index: number;
}

const GRID_SX = { overflow: 'hidden', '&:hover': { backgroundColor: AB1 } };
const TOOLTIP_SX = { flex: 1 };
const CONTAINER_HEIGHT = 48;

const DashboardGroupSecurityScoreWidgetListItem = (
  props: IDashboardGroupSecurityScoreWidgetListItemProps,
) => {
  const { group, index } = props;
  const {
    groupId, name, groupSecurityScore,
  } = group ?? {};
  const navigate = useNavigate();
  const { t } = useTranslations();

  const handleNavigate = useCallback(() => {
    const search = createSearchParams({
      columnOrderBy: lowerFirst(ApplicationOrderPropertyName.SecurityScore),
      columnOrderDirection: 'asc',
    }).toString();
    navigate({
      pathname: `${PageRouteEnum.Groups}/${groupId}/${GroupModuleTabEnum.Members}`,
      search,
    });
  }, [navigate, groupId]);

  return (
    <ListItem sx={{ backgroundColor: index % 2 === 0 ? S2 : S1 }}>
      <Tooltip
        title={t('dashboardGroupSecurityScoreWidget.view')}
        boxSX={TOOLTIP_SX}
        followCursor
        cursorPointer
      >
        <Grid
          container
          columnGap={1}
          justifyContent="space-between"
          alignItems="center"
          flexWrap="nowrap"
          onClick={handleNavigate}
          paddingX={3}
          paddingY={1}
          height={CONTAINER_HEIGHT}
          sx={GRID_SX}
        >
          <Typography variant="body1" color={S6} noWrap>
            {name}
          </Typography>
          <SecurityScoreIcon percentage={groupSecurityScore ? Math.floor(groupSecurityScore) : 0} />
        </Grid>
      </Tooltip>
    </ListItem>
  );
};

export default memo(DashboardGroupSecurityScoreWidgetListItem);
