import { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Panel,
  useSnackbar,
  useTranslations,
  GroupIcon,
  FilterButton,
  ActionButton,
  Divider,
  DeleteIcon,
  usePopper,
  SearchableTextField,
  Tooltip,
  generateSymmetricKey,
  encryptAsymmetric,
  useMapKeyValueExtractor,
  PromptModal,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import GroupModal from './components/GroupModal';
import useEmployeeGroupsAPI from '../../hooks/useEmployeeGroupsAPI';
import useEmployeeAccountsToGroupsAPI from '../../hooks/useEmployeeAccountsToGroupsAPI';
import { useUser } from '../../contexts/UserContext';
import GroupsListFilter, { IGroupsListFilterSubmitResult } from './components/GroupsListFilter';
import EmployeesSelectorModal from './components/EmployeesSelectorModal';
import GroupsTable, { IGroupsTableRow } from '../../components/tables/GroupsTable';
import useGroupsTable from '../../hooks/tables/useGroupsTable';
import {
  useDeleteGroups,
  useGetCurrentOrganization,
  useGetOrganizationScimSettings,
} from '../../hooks/reactQuery';
import { logException } from '../../services/sentryService';
import { generateTooltipTitle } from '../../helpers/tooltips';
import { getTranslationKeyByError } from '../../helpers/errorService';

export interface ICreateGroup {
  name: string;
  description: string;
  addToGroup: boolean;
}

const GroupsPage = () => {
  const { t } = useTranslations();
  const { currentEmployee } = useUser();
  const employeeAccountId = currentEmployee?.id ?? '';

  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
  const [isGroupDeleteModalOpen, setIsGroupDeleteModalOpen] = useState(false);
  const handleCreateGroupModalOpen = useCallback(() => setIsCreateGroupModalOpen(true), []);
  const handleCreateGroupModalClose = useCallback(() => setIsCreateGroupModalOpen(false), []);
  const handleGroupDeleteModalOpen = useCallback(() => setIsGroupDeleteModalOpen(true), []);
  const handleGroupDeleteModalClose = useCallback(() => setIsGroupDeleteModalOpen(false), []);
  const [isGroupCreateLoading, setIsGroupCreateLoading] = useState(false);
  const [isGroupDeleteLoading, setIsGroupDeleteLoading] = useState(false);

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const { showSuccess, showError } = useSnackbar();
  const { createGroup } = useEmployeeGroupsAPI();
  const { inviteBulkToGroup } = useEmployeeAccountsToGroupsAPI();

  const {
    selectedGroups,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    isLoading: areGroupsLoading,
    ...restTableProps
  } = useGroupsTable({ noDataMessageKey: 'groupsPage.noData' });

  const {
    data: scim,
    isLoading: isGetOrganizationScimSettingsLoading,
    isError: isGetOrganizationScimSettingsError,
  } = useGetOrganizationScimSettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const { secretToken } = scim ?? {};

  const [isEmployeesSelectorModalOpen, setIsEmployeesSelectorModalOpen] = useState(false);
  const handleEmployeesSelectorModalOpen = useCallback(
    () => setIsEmployeesSelectorModalOpen(true),
    [],
  );
  const handleEmployeesSelectorModalClose = useCallback(
    () => setIsEmployeesSelectorModalOpen(false),
    [],
  );
  const {
    values: selectedGroupsAsObjects, keys: selectedGroupsIds,
  } = useMapKeyValueExtractor<IGroupsTableRow>(selectedGroups);

  const { mutate } = useDeleteGroups();

  const { data: organization } = useGetCurrentOrganization();
  const { organizationPublicKey = '' } = organization ?? {};

  const handleGroupCreate = useCallback(async (params: ICreateGroup): Promise<void> => {
    const { name, description, addToGroup } = params;
    setIsGroupCreateLoading(true);
    try {
      const groupSecret = await encryptAsymmetric({
        publicKey: organizationPublicKey,
        string: generateSymmetricKey(),
      });
      const { groupId } = await createGroup({ name, description, groupSecret });
      if (addToGroup) {
        await inviteBulkToGroup(
          { employeeAccountsIds: [employeeAccountId], groupsIds: [groupId] },
        );
      }
      showSuccess({
        text: t('groupModal.groupCreated'),
      });
      handleCreateGroupModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupsPage/handleGroupCreate exception',
      });
    } finally {
      setIsGroupCreateLoading(false);
    }
  }, [
    organizationPublicKey,
    employeeAccountId,
    createGroup,
    inviteBulkToGroup,
    handleCreateGroupModalClose,
    showError,
    showSuccess,
    t,
  ]);

  const handleDeleteGroup = useCallback(async () => {
    setIsGroupDeleteLoading(true);
    mutate({ groupIds: selectedGroupsIds }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteGroupModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteGroupModal.errorMessage', { count: failCount }),
          });
        }
        handleGroupDeleteModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t(getTranslationKeyByError(e)) });
        logException(e, {
          message: 'GroupsPage/handleDeleteGroup exception',
        });
      },
      onSettled: () => setIsGroupDeleteLoading(false),
    });
  }, [
    selectedGroupsIds,
    mutate,
    handleGroupDeleteModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );
  const handleFilterSubmit = useCallback((updatedValues: IGroupsListFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const { addMembersTooltipTitle, deleteTooltipTitle } = useMemo(() => {
    const addMembersTitle = generateTooltipTitle({
      selectedDataLength: selectedGroups.size,
      t,
      isDisabled: false,
      key: 'groupsPage.addMembers',
    });
    const deleteTitle = generateTooltipTitle({
      selectedDataLength: selectedGroups.size,
      t,
      isDisabled: false,
      key: 'groupsPage.delete',
    });

    return {
      addMembersTooltipTitle: addMembersTitle,
      deleteTooltipTitle: deleteTitle,
    };
  }, [selectedGroups.size, t]);

  if (isGetOrganizationScimSettingsError) {
    return null;
  }

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
                <Grid item>
                  <Tooltip title={t('common.filter')}>
                    <FilterButton
                      isFilterActive={isFilterActive}
                      numberOfActiveFilters={numberOfActiveFilters}
                      selected={isFilterOpen}
                      onChange={toggleIsFilterOpen}
                    />
                  </Tooltip>
                </Grid>
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={addMembersTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleEmployeesSelectorModalOpen}
                      disabled={!selectedGroups.size}
                    >
                      <GroupIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item alignSelf="center">
                  <Tooltip title={deleteTooltipTitle}>
                    <ActionButton
                      width={40}
                      height={40}
                      onClick={handleGroupDeleteModalOpen}
                      disabled={!selectedGroups.size}
                    >
                      <DeleteIcon />
                    </ActionButton>
                  </Tooltip>
                </Grid>
                <Grid item my={0.5}>
                  <Divider orientation="vertical" />
                </Grid>
              </Grid>
              <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <Box mr={3} />
                <Grid>
                  <Button icon={<GroupIcon />} onClick={handleCreateGroupModalOpen}>
                    {t('groupsPage.createGroupButton')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          <GroupsTable
            selectedGroups={selectedGroups}
            isSecretTokenEmpty={!secretToken}
            isLoading={areGroupsLoading || isGetOrganizationScimSettingsLoading}
            {...restTableProps}
          />
        </Panel>
      </Grid>
      {isCreateGroupModalOpen && (
        <GroupModal
          handleGroupCreate={handleGroupCreate}
          isModalOpen={isCreateGroupModalOpen}
          handleModalClose={handleCreateGroupModalClose}
          isLoading={isGroupCreateLoading}
        />
      )}
      <GroupsListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues as IGroupsListFilterSubmitResult}
      />
      {isEmployeesSelectorModalOpen && (
        <EmployeesSelectorModal
          isOpen={isEmployeesSelectorModalOpen}
          onClose={handleEmployeesSelectorModalClose}
          groupsIds={selectedGroupsIds}
        />
      )}
      {isGroupDeleteModalOpen && (
        <PromptModal
          open={isGroupDeleteModalOpen}
          onClose={handleGroupDeleteModalClose}
          onSubmit={handleDeleteGroup}
          title={t('deleteGroupModal.title', { count: selectedGroups.size })}
          description={t('deleteGroupModal.description')}
          approvalButtonText="common.delete"
          list={selectedGroupsAsObjects}
          renderField="name"
          renderKey="groupId"
          isLoading={isGroupDeleteLoading}
        />
      )}
    </Grid>
  );
};

export default GroupsPage;
