import {
  useCallback, memo, useState, useMemo,
} from 'react';
import { useSnackbar, useTranslations } from '@uniqkey-frontend/shared-app';
import {
  GetVaultCreditCardsResponseModel,
  GetVaultPasswordsResponseModel,
  GetVaultSecureNotesResponseModel,
  GetGroupsResponseModel,
  GetGroupByIdResponse,
} from '@uniqkey-backend-organization-web/api-client';
import EntitySelectorModal, { TEntitySelectorModalOnSubmit } from '../EntitySelectorModal';
import { logException } from '../../services/sentryService';
import useEmployeeGroupsAPI from '../../hooks/useEmployeeGroupsAPI';
import { getActiveOrganizationId } from '../../services/organizationService';
import { useTrustedPortalStore } from '../../modules/TrustedPortalModule/store';
import VaultTypeNameEnum from '../../enums/VaultTypeNameEnum';
import VaultTypeEnum from '../../enums/VaultTypeEnum';
import useVaultsAPI from '../../hooks/useVaultsAPI';
import { getTranslationKeyByError } from '../../helpers/errorService';

interface IMoveVaultsToGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  vaults: GetVaultPasswordsResponseModel[] | GetVaultSecureNotesResponseModel[]
    | GetVaultCreditCardsResponseModel[];
  onSubmit?: () => void;
  type: VaultTypeEnum;
}

interface IParsedEntity {
  id: GetGroupByIdResponse['groupId'];
  label: GetGroupByIdResponse['name'];
}

const MoveVaultsToGroupModal = (props: IMoveVaultsToGroupModalProps) => {
  const {
    isOpen, onClose, vaults, onSubmit, type,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadedGroupsCount, setLoadedGroupsCount] = useState<number>(0);
  const [totalLoadedGroupsCount, setTotalLoadedGroupsCount] = useState<number>(0);
  const [response, setResponse] = useState<GetGroupsResponseModel[]>([]);
  const { t } = useTranslations();
  const { showWarning, showError, showSuccess } = useSnackbar();
  const { getGroups } = useEmployeeGroupsAPI();
  const { moveVaultsToGroup } = useVaultsAPI();

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data, total } = await getGroups({
        page: 1, pageLength: 200, searchQuery, canManageVaults: true,
      });
      setLoadedGroupsCount(data.length);
      setTotalLoadedGroupsCount(total);
      setResponse(data);
      return data;
    } catch (e) {
      setLoadedGroupsCount(0);
      setTotalLoadedGroupsCount(0);
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'MoveVaultsToGroupModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getGroups, showError, t]);

  const vaultType = useMemo(
    () => VaultTypeNameEnum[VaultTypeEnum[type] as keyof typeof VaultTypeEnum],
    [type],
  );

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity>
  >(async (value) => {
    try {
      setIsLoading(true);
      const vaultIds = vaults.map((vault) => vault.vaultId);
      await moveVaultsToGroup({ vaultIds, groupId: value.id });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t(
            `trustedPortalSuccessNotifications.${vaultType}.movedToGroup`,
            { count: vaults.length, name: value.label },
          ),
        });
      } else {
        showWarning({ text: t('moveEntityToGroupModal.approvalOnMobileToast') });
      }
      if (onSubmit) {
        onSubmit();
      }
      setIsLoading(false);
      onClose();
    } catch (e) {
      showError({ text: t(getTranslationKeyByError(e)) });
      logException(e, {
        message: 'MoveVaultsToGroupModal/handleSubmitEntitySelectorModal exception',
      });
      setIsLoading(false);
    }
  }, [
    vaults,
    isTrustedPortalEnabled,
    onSubmit,
    onClose,
    showSuccess,
    t,
    vaultType,
    showWarning,
    showError,
    moveVaultsToGroup,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (groupsToParse: GetGroupByIdResponse[]) => groupsToParse.map((group: GetGroupByIdResponse) => ({
      id: group.groupId,
      label: group.name,
    })),
    [],
  );

  return (
    <EntitySelectorModal
      data={response}
      dataKey="groupId"
      isOpen={isOpen}
      title={t(`moveEntityToGroupModal.${vaultType}.title`, { count: vaults.length })}
      dialogContentText={t('moveEntityToGroupModal.description')}
      placeholder={t('moveEntityToGroupModal.placeholder')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isLoading}
      multiple={false}
      submitButtonText="common.move"
      loadedOptionsCount={loadedGroupsCount}
      totalLoadedOptionsCount={totalLoadedGroupsCount}
    />
  );
};

export default memo(MoveVaultsToGroupModal);
