import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  usePubSub,
  buildFilterOptionsAsObject,
  useSnackbar,
  TSecurityScoreName,
  ISecurityScoreOption,
  getSecurityScoreOptions,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  EmployeeAccountStatus,
  EmployeeAccountType,
  ActivityFilter,
  GetGroupsV2ResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { IGetEmployeeAccountsParams } from '../../../../hooks/useEmployeeAccountsAPI/interfaces';
import PubSubEventEnum from '../../../../enums/PubSubEventEnum';
import {
  ACTIVITY_FILTER_TRANSLATION_KEYS,
  EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS,
  EMPLOYEE_ACCOUNT_TYPE_TRANSLATION_KEYS,
} from '../../../../constants';
import useEmployeeGroupsAPI from '../../../../hooks/useEmployeeGroupsAPI';
import { logException } from '../../../../services/sentryService';

export interface IEmployeesListFilterSubmitResult {
  statusFilter: IGetEmployeeAccountsParams['statusFilter'];
  typeFilter: IGetEmployeeAccountsParams['typeFilter'];
  securityScoreName: TSecurityScoreName;
  uniqkeyAccessScoreName: TSecurityScoreName;
  activityFilter: IGetEmployeeAccountsParams['activityFilter'];
  group: IGroupOption | undefined;
}

interface IGroupOption {
  value: GetGroupsV2ResponseModel['groupId'];
  label: GetGroupsV2ResponseModel['name'];
}

interface IEmployeesListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IEmployeesListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IEmployeesListFilterSubmitResult;
}

type TStatusFilterOption = {
  label: string;
  value: IEmployeesListFilterSubmitResult['statusFilter'];
} | null;

type TTypeFilterOption = {
  label: string;
  value: IEmployeesListFilterSubmitResult['typeFilter'];
} | null;

type TActivityFilterOption = {
  label: string;
  value: IEmployeesListFilterSubmitResult['activityFilter'];
} | null;

interface IFormValues {
  statusFilter: TStatusFilterOption;
  typeFilter: TTypeFilterOption;
  securityScore: ISecurityScoreOption | null;
  uniqkeyAccessScore: ISecurityScoreOption | null;
  activityFilter: TActivityFilterOption;
  group: IGroupOption | null;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const EmployeesListFilter = (props: IEmployeesListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getGroups } = useEmployeeGroupsAPI();

  const [statusFilterOptions, statusFilterOptionsAsObject] = useMemo(() => {
    const options = [
      {
        label: t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[EmployeeAccountStatus.Active]),
        value: EmployeeAccountStatus.Active,
      },
      {
        label: t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[EmployeeAccountStatus.Archived]),
        value: EmployeeAccountStatus.Archived,
      },
      {
        label: t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[EmployeeAccountStatus.Invited]),
        value: EmployeeAccountStatus.Invited,
      },
      {
        label: t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[EmployeeAccountStatus.Unprocessed]),
        value: EmployeeAccountStatus.Unprocessed,
      },
      {
        label: t(
          EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[EmployeeAccountStatus.ExistingUnprocessed],
        ),
        value: EmployeeAccountStatus.ExistingUnprocessed,
      },
      {
        label: t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[EmployeeAccountStatus.Migrated]),
        value: EmployeeAccountStatus.Migrated,
      },
      {
        label: t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[EmployeeAccountStatus.MigrationInvited]),
        value: EmployeeAccountStatus.MigrationInvited,
      },
      {
        label: t(EMPLOYEE_ACCOUNT_STATUS_TRANSLATION_KEYS[EmployeeAccountStatus.Staged]),
        value: EmployeeAccountStatus.Staged,
      },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [typeFilterOptions, typeFilterOptionsAsObject] = useMemo(() => {
    const options = [
      {
        label: t(EMPLOYEE_ACCOUNT_TYPE_TRANSLATION_KEYS[EmployeeAccountType.Admin]),
        value: EmployeeAccountType.Admin,
      },
      {
        label: t(EMPLOYEE_ACCOUNT_TYPE_TRANSLATION_KEYS[EmployeeAccountType.Employee]),
        value: EmployeeAccountType.Employee,
      },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [securityScoreOptions, securityScoreOptionsAsObject] = useMemo(() => {
    const options = getSecurityScoreOptions(t);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [uniqkeyAccessScoreOptions, uniqkeyAccessScoreOptionsAsObject] = useMemo(() => {
    const options = getSecurityScoreOptions(t);
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const [activityFilterOptions, activityFilterOptionsAsObject] = useMemo(() => {
    const options = [
      {
        label: t(ACTIVITY_FILTER_TRANSLATION_KEYS[ActivityFilter.Active]),
        value: ActivityFilter.Active,
      },
      {
        label: t(ACTIVITY_FILTER_TRANSLATION_KEYS[ActivityFilter.NotActive]),
        value: ActivityFilter.NotActive,
      },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      statusFilter: statusFilterOptionsAsObject[
        initialValues.statusFilter as keyof typeof statusFilterOptionsAsObject
      ] ?? null,
      typeFilter: typeFilterOptionsAsObject[
        initialValues.typeFilter as keyof typeof typeFilterOptionsAsObject
      ] ?? null,
      securityScore: securityScoreOptionsAsObject[
        initialValues.securityScoreName as keyof typeof securityScoreOptionsAsObject
      ] ?? null,
      uniqkeyAccessScore: uniqkeyAccessScoreOptionsAsObject[
        initialValues.uniqkeyAccessScoreName as keyof typeof uniqkeyAccessScoreOptionsAsObject
      ] ?? null,
      activityFilter: activityFilterOptionsAsObject[
        initialValues.activityFilter as keyof typeof activityFilterOptionsAsObject
      ] ?? null,
      group: initialValues.group ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const {
      statusFilter, typeFilter, securityScore, activityFilter, group, uniqkeyAccessScore,
    } = value;
    onSubmit({
      statusFilter: statusFilter?.value,
      typeFilter: typeFilter?.value,
      securityScoreName: securityScore?.value,
      uniqkeyAccessScoreName: uniqkeyAccessScore?.value,
      activityFilter: activityFilter?.value,
      group: group ?? undefined,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const handleGetGroupsRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getGroups({ page: 1, pageLength: 100, searchQuery });
      return data;
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeesListFilter/handleGetGroupsRequest exception',
      });
      return [];
    }
  }, [getGroups, showError, t]);

  const handleGetGroupsResponseParser = useCallback(
    (groupsToParse: GetGroupsV2ResponseModel[]) => groupsToParse.map(
      (group: GetGroupsV2ResponseModel) => ({
        value: group.groupId,
        label: group.name,
      }),
    ),
    [],
  );

  const clearAll = useCallback(() => {
    setValue('statusFilter', null, { shouldDirty: true });
    setValue('typeFilter', null, { shouldDirty: true });
    setValue('securityScore', null, { shouldDirty: true });
    setValue('uniqkeyAccessScore', null, { shouldDirty: true });
    setValue('activityFilter', null, { shouldDirty: true });
    setValue('group', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      statusFilter: null,
      typeFilter: null,
      securityScore: null,
      uniqkeyAccessScore: null,
      activityFilter: null,
      group: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('employeesFilter.employeeStatus.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="statusFilter"
                  options={statusFilterOptions}
                  placeholder={t('employeesFilter.employeeStatus.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('employeesFilter.adminRights.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="typeFilter"
                  options={typeFilterOptions}
                  placeholder={t('employeesFilter.adminRights.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('employeesFilter.securityScore.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="securityScore"
                  options={securityScoreOptions}
                  placeholder={t('employeesFilter.securityScore.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('employeesFilter.uniqkeyAccessScore.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="uniqkeyAccessScore"
                  options={uniqkeyAccessScoreOptions}
                  placeholder={t('employeesFilter.uniqkeyAccessScore.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('employeesFilter.activityFilter.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="activityFilter"
                  options={activityFilterOptions}
                  placeholder={t('employeesFilter.activityFilter.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('employeesFilter.groups.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  t={t}
                  name="group"
                  placeholder={t('employeesFilter.groups.placeholder')}
                  dataSourceRequest={handleGetGroupsRequest}
                  dataSourceResponseParser={handleGetGroupsResponseParser}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(EmployeesListFilter);
