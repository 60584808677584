import {
  useMutation, useQuery, useQueryClient, type QueryKey,
} from 'react-query';
import {
  TUseMutationContext,
  TUseMutationOptions,
  TUseQueryOptions,
} from '@uniqkey-frontend/shared-app';
import {
  DeleteBulkGroupRequest,
  DeleteBulkGroupResponse,
  GetGroupByIdV2Response,
  NoContentResult,
  Operation,
  GetLowSecurityGroupsResponse,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { IReplacePatchOperation, parseReplacePatchOperation } from '../../../helpers/apiClients';
import useEmployeeGroupsAPI from '../../useEmployeeGroupsAPI';

export const REACT_QUERY_GROUP_KEY = [ReactQueryKeyEnum.Group];

export const REACT_QUERY_DASHBOARD_LOW_SECURITY_SCORE_GROUPS = [
  ReactQueryKeyEnum.DashboardLowSecurityScoreGroups,
];

interface IUseGetGroupByIdParams {
  groupId: string;
}
export const useGetGroupById = (
  params: IUseGetGroupByIdParams,
  options: TUseQueryOptions<GetGroupByIdV2Response> = {},
) => {
  const { groupId } = params;
  const { getGroupById } = useEmployeeGroupsAPI();
  return useQuery<GetGroupByIdV2Response>(
    (REACT_QUERY_GROUP_KEY as QueryKey[]).concat([groupId]),
    ({ signal }) => getGroupById(groupId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUsePatchGroupByIdParams {
  groupId: string;
  useOptimisticUpdates?: boolean;
}
export const usePatchGroupById = (
  params: IUsePatchGroupByIdParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    Operation[],
    TUseMutationContext<GetGroupByIdV2Response>
  > = {},
) => {
  const { groupId, useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { patchGroupById } = useEmployeeGroupsAPI();
  const mutationKey = (REACT_QUERY_GROUP_KEY as QueryKey[]).concat([groupId]);
  return useMutation(mutationKey, (operations) => patchGroupById(groupId, operations), {
    onMutate: async (operations) => {
      if (!useOptimisticUpdates) {
        return null;
      }
      const updatedParts = operations.reduce((acc, operation) => ({
        ...acc,
        ...parseReplacePatchOperation(operation as IReplacePatchOperation),
      }), {});
      await queryClient.cancelQueries(mutationKey);
      const previousValue = queryClient.getQueryData<GetGroupByIdV2Response>(mutationKey);
      queryClient.setQueryData<GetGroupByIdV2Response>(
        mutationKey,
        (oldGroup) => ({
          ...oldGroup,
          ...updatedParts as GetGroupByIdV2Response,
        }),
      );
      return { previousValue: previousValue as GetGroupByIdV2Response };
    },
    onError: (err, group, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData<GetGroupByIdV2Response>(mutationKey, context.previousValue);
      }
    },
    ...options,
  });
};

export const useDeleteGroups = (
  options: TUseMutationOptions<DeleteBulkGroupResponse, unknown, DeleteBulkGroupRequest, void> = {},
) => {
  const queryClient = useQueryClient();
  const { deleteGroups } = useEmployeeGroupsAPI();
  return useMutation((deleteBulkGroupRequest) => deleteGroups(deleteBulkGroupRequest), {
    onSettled: (data, error, variables) => {
      variables.groupIds.forEach((groupId) => {
        queryClient.removeQueries((REACT_QUERY_GROUP_KEY as QueryKey[]).concat([groupId]));
      });
    },
    ...options,
  });
};

export const useLowSecurityScoreGroups = (
  options: TUseQueryOptions<GetLowSecurityGroupsResponse> = {},
) => {
  const { getLowSecurityScoreGroups } = useEmployeeGroupsAPI();
  return useQuery<GetLowSecurityGroupsResponse>(
    REACT_QUERY_DASHBOARD_LOW_SECURITY_SCORE_GROUPS,
    ({ signal }) => getLowSecurityScoreGroups({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
