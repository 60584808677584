import { WidgetContainer } from '@uniqkey-frontend/shared-app';
import { GetRetentionPeriodResponse } from '@uniqkey-backend-organization-web/api-client';
import { memo } from 'react';
import GeneralRetentionPeriodWidgetContainer, {
  IUpdateGeneralRetentionPeriodSubmitResult,
} from '../GeneralRetentionPeriodWidgetContainer';

export interface IGeneralRetentionPeriodWidgetProps {
  retentionPeriodInDays: GetRetentionPeriodResponse['retentionPeriodInDays'];
  isLoading: boolean;
  isError: boolean;
  onSubmit: (value: IUpdateGeneralRetentionPeriodSubmitResult) => void;
  isUpdating: boolean;
}

const GeneralRetentionPeriodWidget = (props: IGeneralRetentionPeriodWidgetProps) => {
  const {
    retentionPeriodInDays, isLoading, isError, onSubmit, isUpdating,
  } = props;

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <WidgetContainer container withShadow minHeight={240} p={3} isLoading={isLoading} />
    );
  }

  return (
    <GeneralRetentionPeriodWidgetContainer
      retentionPeriodInDays={retentionPeriodInDays}
      onSubmit={onSubmit}
      isUpdating={isUpdating}
    />
  );
};

export default memo(GeneralRetentionPeriodWidget);
