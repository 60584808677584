import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetSecuritySettingsResponse,
  UpdateSecuritySettingsRequest,
  NoContentResult,
  GetRetentionPeriodResponse,
  UpdateSoftDeletionRetentionPeriodRequest,
  UpdateRetentionPeriodRequest,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useOrganizationSecuritySettingsAPI from '../../useOrganizationSecuritySettingsAPI';

export const REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY = [
  ReactQueryKeyEnum.OrganizationSecuritySettings,
];

export const REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY = [
  ReactQueryKeyEnum.OrganizationRetentionPeriod,
];

export const useGetOrganizationSecuritySettings = (
  options: TUseQueryOptions<GetSecuritySettingsResponse> = {},
) => {
  const { getOrganizationSecuritySettings } = useOrganizationSecuritySettingsAPI();
  return useQuery<GetSecuritySettingsResponse>(
    REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY,
    ({ signal }) => getOrganizationSecuritySettings({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateOrganizationSecuritySettingsParams {
  useOptimisticUpdates?: boolean;
}
export const useUpdateOrganizationSecuritySettings = (
  params: IUseUpdateOrganizationSecuritySettingsParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdateSecuritySettingsRequest,
    TUseMutationContext<UpdateSecuritySettingsRequest>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateOrganizationSecuritySettings } = useOrganizationSecuritySettingsAPI();
  return useMutation(REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY, (
    securitySettings,
  ) => updateOrganizationSecuritySettings(securitySettings), {
    onMutate: async (newSettings: UpdateSecuritySettingsRequest) => {
      if (!useOptimisticUpdates) {
        return null;
      }
      await queryClient.cancelQueries(REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY);
      const previousValue = queryClient.getQueryData<UpdateSecuritySettingsRequest>(
        REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY,
      );
      queryClient.setQueryData<UpdateSecuritySettingsRequest>(
        REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY,
        (oldSettings) => ({
          ...oldSettings,
          ...newSettings,
        }),
      );
      return { previousValue: previousValue as UpdateSecuritySettingsRequest };
    },
    onError: (err, settings, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData<UpdateSecuritySettingsRequest>(
          REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY,
          context.previousValue,
        );
      }
    },
    ...options,
  });
};

export const useGetOrganizationRetentionPeriod = (
  options: TUseQueryOptions<GetRetentionPeriodResponse> = {},
) => {
  const { getOrganizationRetentionPeriod } = useOrganizationSecuritySettingsAPI();
  return useQuery<GetRetentionPeriodResponse>(
    REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY,
    ({ signal }) => getOrganizationRetentionPeriod({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUpdateSoftDeletionRetentionPeriodParams {
  useOptimisticUpdates?: boolean;
}
export const useUpdateSoftDeletionRetentionPeriod = (
  params: IUpdateSoftDeletionRetentionPeriodParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdateSoftDeletionRetentionPeriodRequest,
    TUseMutationContext<UpdateSoftDeletionRetentionPeriodRequest>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateSoftDeletionRetentionPeriod } = useOrganizationSecuritySettingsAPI();
  return useMutation(REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY, (
    securitySettings,
  ) => updateSoftDeletionRetentionPeriod(securitySettings), {
    onMutate: async (newSettings: UpdateSoftDeletionRetentionPeriodRequest) => {
      if (!useOptimisticUpdates) {
        return null;
      }
      await queryClient.cancelQueries(REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY);
      const previousValue = queryClient.getQueryData<UpdateSoftDeletionRetentionPeriodRequest>(
        REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY,
      );
      queryClient.setQueryData<UpdateSoftDeletionRetentionPeriodRequest>(
        REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY,
        (oldSettings) => ({
          ...oldSettings,
          ...newSettings,
        }),
      );
      return { previousValue: previousValue as UpdateSoftDeletionRetentionPeriodRequest };
    },
    onError: (err, settings, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData<UpdateSoftDeletionRetentionPeriodRequest>(
          REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY,
          context.previousValue,
        );
      }
    },
    ...options,
  });
};

interface IUpdateGeneralRetentionPeriodParams {
  useOptimisticUpdates?: boolean;
}
export const useUpdateGeneralRetentionPeriod = (
  params: IUpdateGeneralRetentionPeriodParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    UpdateRetentionPeriodRequest,
    TUseMutationContext<UpdateRetentionPeriodRequest>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateGeneralRetentionPeriod } = useOrganizationSecuritySettingsAPI();
  return useMutation(REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY, (
    securitySettings,
  ) => updateGeneralRetentionPeriod(securitySettings), {
    onMutate: async (newSettings: UpdateRetentionPeriodRequest) => {
      if (!useOptimisticUpdates) {
        return null;
      }
      await queryClient.cancelQueries(REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY);
      const previousValue = queryClient.getQueryData<UpdateRetentionPeriodRequest>(
        REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY,
      );
      queryClient.setQueryData<UpdateRetentionPeriodRequest>(
        REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY,
        (oldSettings) => ({
          ...oldSettings,
          ...newSettings,
        }),
      );
      return { previousValue: previousValue as UpdateRetentionPeriodRequest };
    },
    onError: (err, settings, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData<UpdateRetentionPeriodRequest>(
          REACT_QUERY_ORGANIZATION_RETENTION_PERIOD_KEY,
          context.previousValue,
        );
      }
    },
    ...options,
  });
};
